
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    path: { type: Array, required: true },
    darkModeMobile: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const length = props.path?.length || 0
    const last = (list) => list[list.length - 1]
    return {
      last,
      length,
      trunkCrumbs: props.path?.slice(0, length - 1) || [],
      leafCrumb: last(props.path),
    }
  },
})
