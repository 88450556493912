import { render, staticRenderFns } from "./NavigationBreadcrumb.vue?vue&type=template&id=710cfe1c&scoped=true"
import script from "./NavigationBreadcrumb.vue?vue&type=script&lang=js"
export * from "./NavigationBreadcrumb.vue?vue&type=script&lang=js"
import style0 from "./NavigationBreadcrumb.vue?vue&type=style&index=0&id=710cfe1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710cfe1c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIconArrowLeft: require('/home/userapp/components/Atom/Icon/IconArrowLeft.vue').default,AtomLink: require('/home/userapp/components/Atom/Link/Link.vue').default})
